<template>
    <div class="auction " :class="$route.query.isnew==1?'subchild-box':''"  ref="scrolling"  id="scrollbox" >
        <Head :title="$route.query.isnew==1?$t('mine.newer'):$t('common.auction')" :right="true" :show="$route.query.isnew==1">
            <img @click="gotoQuestion" src="../../assets/imgs/rules.png" class="rules-icon" alt="">
        </Head>
        <div class="main-box auction-box" v-if="cateproductList" >
            <div class="auction-main" >
                <div class="auction-nav">
                    <div class="nav-txt" @click="showAllData">
                        {{$t('auction.fen_lan')}}
                    </div>
                    <div class="nav-slide">
                        <van-sidebar v-model="activeKey">
                            <!-- <van-sidebar-item :title="$t('common.all')"  key="-1"/> -->
                            <van-sidebar-item :title="item.title" v-for="(item,i) in cateproductList" :key="i"/>
                        </van-sidebar>
                    </div>
                </div>
                <div class="nav-main" v-if="item.products">
                    <van-tabs :sticky="true" :animated="true" :swipeable="true" v-model="active" class="mytabs">
                        <van-tab title="Cloud Bid"  name="1" >
                            <AuctionList :scrolltop="scrollTop" :timeendaction="timeEndAction" :isnew="$route.query.isnew==1" :cb="true" :tt="false" :item="cateproductList[activeKey]" :list="item.products.filter(v=>v.active_id==active)"></AuctionList>
                        </van-tab>
                        <van-tab title="Time Twister" name="2" >
                            <AuctionList :scrolltop="scrollTop" :timeendaction="timeEndAction"  :isnew="$route.query.isnew==1"  :tt="true" :cb="false" :item="cateproductList[activeKey]" :list="item.products.filter(v=>v.active_id==active)"></AuctionList>
                        </van-tab>
                    </van-tabs>
                </div>
            </div>
        </div>
        <PlayRule ref="play" :changescroll="changescroll" ></PlayRule>

        
    </div>
</template>

<script>
import {mapState,mapMutations} from "vuex"
// import {myinfo} from "@/common"
var scrollTop = 0 ;
export default {
    // mixins:[myinfo],  //获取个人信息数据
    name:'main-auctionIndex',
    computed:{
        ...mapState(['jiqigoodList','homewinnerList']),
        activeKey:{
            get(){
                return this.$store.state.activeKey
            },
            set(v){
                this.$store.commit("changeactiveKey",v);
                this.item = this.cateproductList[v]
            }
        },
        active:{
            get(){
                return this.$store.state.active
            },
            set(v){
                this.$store.commit("changeactive",v);
            }
        }
    },
    watch:{
        homewinnerList(v){
            var obj = v[v.length-1]
            var data = {};
            if(obj['tt_winninginfo']){
                data = obj['tt_winninginfo']
            }else{
                data = obj['cb_winninginfo']
            }       
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    if(item.good_id==data.good_id&&item.qishu==data.qishu){
                        item = {...item,resultOut:true}
                    }
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        },
        jiqigoodList(v){
            var new_data = v[0];
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    // console.log(item)
                    if(item.active_id==1){
                        // cb
                        if(new_data['buy_cb']){
                            if(new_data['buy_cb'].qishu==item.qishu&&new_data['buy_cb'].good_id==item.id){
                                // 推送了一条 cb 数据
                                item = {
                                    ...item,
                                    join_num:item.join_num*1 + new_data['buy_cb'].total*1 
                                }
                            }
                        }
                    }
                    if(item.active_id==2){
                        // tt 
                        if(new_data['buy_tt']){
                            if(new_data['buy_tt'].qishu==item.qishu&&new_data['buy_tt'].good_id==item.id){
                                // 推送了一条 cb 数据
                                item = {
                                    ...item,
                                    now_price:new_data['buy_tt'].now_price,
                                    day:new_data['buy_tt'].day,
                                    hours:new_data['buy_tt'].hours,
                                    mins:new_data['buy_tt'].mins,
                                    second:new_data['buy_tt'].second,
                                    timeend:false,   // 倒计时继续
                                    countKey:new Date().getTime(),
                                    timeCount:parseInt(new_data['buy_tt'].day) * 24 * 3600 * 1000 + parseInt(new_data['buy_tt'].hours)  * 3600 * 1000 + parseInt(new_data['buy_tt'].mins)  *60 * 1000 + parseInt(new_data['buy_tt'].second)  * 1000
                                }
                            }
                        }
                    }
                    // return item;
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        }
    },
    data(){
        return {
            item:[],
            cateproductList:[],
            lang:localStorage.getItem("langData") || 'th',
            allData:[]
        }
    },
    beforeRouteEnter(to,from,next){
        scrollTop = from.query.scrolltop ? from.query.scrolltop  : 0;
        next()
    },
    mounted(){
        
        // this.active = this.$route.query.active ? this.$route.query.active : this.active;
        // 商品列表 
        // cateproductnop  少请求接口   cateproduct 
        this.$ajax.cateproductnop({
        })
        .then(res=>{
            if(res.code==200){
                var list  =  res.data;
                res.data.forEach(item=>{
                    item.products.forEach(val=>{
                        this.allData.push(val)
                    })
                })
                list.unshift({
                    products:this.allData,
                    title: this.$t("common.all"),
                    title_cn: "全部",
                    title_en: "All",
                    title_th: "ทั้งหมด",
                })
                this.cateproductList = list;
                this.item = this.cateproductList[this.activeKey];
            }
        })


        this.scrollTop = scrollTop;
    },
    methods:{
        showAllData(){
        },
        timeEndAction(val){
            for(let i in this.cateproductList){
                for(let k in this.cateproductList[i].products){
                    let item = this.cateproductList[i].products[k];
                    
                    if(val.good_id==item.good_id&&val.qishu==item.qishu){
                        item = {...item, timeend:true} 
                    }
                    this.$set(this.cateproductList[i].products,k,item)
                }
            }
        },
        changescroll(flag){
            if(flag){
                document.getElementById('scrollbox').classList.add('vux-modal-open');
                }else{
                document.getElementById('scrollbox').classList.remove('vux-modal-open');
            }
        },
        stopSome(e){
            e.preventDefault();
        },
        gotowhere(name){
            this.$router.push({
                name
            })
        },
        ...mapMutations(['changecateproductList']),
        gotoQuestion(){
            this.$refs.play.show = true;
        },
    }
}
</script>